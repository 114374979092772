import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import HeaderBottom from "../components/HeaderBottom";

const AboutPageTemplate = ({ data, pageContext, location }) => {
  // const page = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active",
        }}
      />
    <HeaderBottom />
      <main class="site-content" id="wrapper">
  <div class="site-content__inner">
    <div class="site-content__holder">
      <figure class="page-thumbnail page-thumbnail--default">
        <img class="page-bg-logo" src={data.home.frontmatter.logo} alt=""/>
        <div class="ncr-page-decor">
          <div class="ncr-page-decor__layer-1">
            <div class="ncr-page-decor__layer-bg"></div>
          </div>
          <div class="ncr-page-decor__layer-2"></div>
          <div class="ncr-page-decor__layer-3">
            <div class="ncr-page-decor__layer-bg"></div>
          </div>
          <div class="ncr-page-decor__layer-4"></div>
          <div class="ncr-page-decor__layer-5"></div>
          <div class="ncr-page-decor__layer-6"></div>
        </div>
      </figure>
      <h1 class="page-title h3">About Us</h1>
      <div class="page-content">
        <p>
          From very humble beginnings, we strive to make gaming chairs in Indonesia affordable, accessible and up to international standards.
          Since then, we have grown to become one of the leading suppliers in various product categories due to the increasing demand of online shopping
          from sites like Tokopedia and Shopee.
        </p>
        <div class="spacer"></div>
        <div class="world-map">
          {/* <div class="world-map-team world-map-team--left world-map-team--one">
            <figure role="group">
              <figure class="world-map-team__logo" role="group">
                <img src="/assets/img/samples/logo-necromancers-32.png" srcset="/assets/img/samples/logo-necromancers-32@2x.png 2x" alt="Necromancers Logo"/>
              </figure>
              <figcaption>
                <div class="world-map-team__name">Necromancers</div>
                  <div class="world-map-team__country">United States</div>
              </figcaption>
            </figure>
            <div class="world-map-team__anchor"></div>
          </div> */}
          <div class="world-map-team world-map-team--right world-map-team--two">
            <figure role="group">
              <figure class="world-map-team__logo" role="group">
                {/* <img src={data.home.frontmatter.logo} srcset={data.home.frontmatter.logo} alt="Apex Warrior Logo"/> */}
              </figure>
              <figcaption>
                <div class="world-map-team__name">Apex Group</div>
                  <div class="world-map-team__country">Jakarta, Indonesia</div>
                  <div class="world-map-team__country">Surabaya, Indonesia</div>
              </figcaption>
            </figure>
            <div class="world-map-team__anchor"></div>
          </div>
        </div>
        <div class="spacer"></div>
        <h4>Kenapa Apex Warrior?</h4>
        <div class="spacer"></div>
        <p>
          Mungkin kalian bingung kenapa harga kami murah meriah tapi kok keliatanya barangnya bagus". Stop!
          Tidak perlu khawatir! Harga kami bisa sangat murah karena kami adalah supplier dan retailer sekaligus.
          Salah satu slogan kami adalah <strong>"Murah Bukan Artinya Jelek"</strong>. Untuk para konsumen, silahkan buktikan sendiri
          dengan membeli produk kami di Tokopedia. Nah untuk para retailer, silahkan hubungi kami untuk mendapatkan harga grosiran atau kerja sama lainya.
        </p>
        <div class="spacer"></div>
        <div class="row">
          {/* <div class="col-md-4 mb-4 mb-md-0">
            <div class="counter">
              <div class="counter__icon counter__icon--left">
                <svg role="img" class="df-icon df-icon--joystick">
                  <use href="/assets/img/necromancers.svg#joystick"/>
                </svg>
              </div>
              <div class="counter__number">979</div>
              <div class="counter__label">Matches</div>
            </div>
          </div> */}
          <div class="col-md-4 mb-4 mb-md-0">
            <div class="counter">
              <div class="counter__icon counter__icon--left">
                <svg role="img" class="df-icon df-icon--trophy">
                  <use href="/assets/img/necromancers.svg#trophy"/>
                </svg>
              </div>
              <div class="counter__number">343230</div>
              <div class="counter__label">Sales</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="counter">
              <div class="counter__icon counter__icon--left">
                <svg role="img" class="df-icon df-icon--kills">
                  <use href="/assets/img/necromancers.svg#kills"/>
                </svg>
              </div>
              <div class="counter__number">99.9</div>
              <div class="counter__label">Satisfaction Rate</div>
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <figure class="lightbox lightbox--video">
          <a href="https://www.youtube.com/watch?v&#x3D;XE0fU9PCrWE" class="mp_iframe">
            <img src="/assets/img/samples/features-about-us-img-01.jpg" alt=""/>
            <span class="lightbox__icon"></span>
          </a>
        </figure>
      </div>
    </div>
  </div>
</main>
    </Layout>
  );
};

export default AboutPageTemplate;

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    home:markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        logo
        logoHorizontal
        pictureLogo
        brands {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`;
